import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ImageFiller from 'react-image-filler';
import Snow from './Snowfall';
import GalleryLightbox from './GalleryLightbox';
import './App.css';
import './textOutline.css';

const fadeUp = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

function App() {
  const [countdown, setCountdown] = useState('');
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentAlt, setCurrentAlt] = useState('');

  useEffect(() => {
    const targetDate = new Date('2025-05-01T00:00:00');
    const interval = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        setCountdown("Mint is live!");
        clearInterval(interval);
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative font-sans min-h-screen overflow-x-hidden scroll-smooth">
      {/* Background */}
      <div
        className="fixed inset-0 -z-10 bg-cover bg-center"
        style={{ backgroundImage: "url('/background.jpg')" }}
      />

      {/* Snowfall */}
      <div className="fixed inset-0 z-[999] pointer-events-none">
        <Snow />
      </div>

      {/* Navbar */}
      <nav className="fixed top-0 left-0 w-full z-50 bg-white/30 backdrop-blur-lg shadow-md px-6 py-4 flex justify-center">
        <ul className="flex flex-wrap justify-center space-x-4 sm:space-x-6 text-sky-800 font-medium font-sourgummy">
          <li><a href="#about" className="hover:underline">Brand</a></li>
          <li><a href="#team" className="hover:underline">Team</a></li>
          <li><a href="#merch" className="hover:underline">NüBox</a></li>
          <li><a href="#mint" className="hover:underline">Frostys Stuido</a></li>
          <li><a href="#gallery" className="hover:underline">Gallery</a></li>
        </ul>
      </nav>

      {/* Floating Logo */}
      <a href="#top" className="fixed top-20 left-1/2 transform -translate-x-1/2 z-40">
        <img
          src="/frostys-logo.png"
          alt="Frostys Logo"
          className="w-16 h-16 sm:w-20 sm:h-20 object-contain hover:scale-110 transition-transform"
        />
      </a>

      {/* Hero */}
      <header id="top" className="relative text-center pt-36 pb-24 text-white">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className="mb-4 relative z-20"
        >
          <h1 className="text-5xl font-sourgummy drop-shadow-lg bg-black/50 inline-block px-6 py-3 rounded-xl shimmer-title">
            Frostys
          </h1>
        </motion.div>

        
      </header>

      {/* About */}
      <motion.section
        id="about"
        className="scroll-mt-28 p-10 m-4 my-20 max-w-4xl mx-auto rounded-xl shadow bg-black/50 text-white"
        variants={fadeUp}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-sourgummy mb-4">The Brand</h2>
        <div className="bg-black/40 p-4 rounded-lg">
          <p className="text-lg leading-relaxed">
            We’re crafting a brand that seamlessly blends digital and physical experiences, bringing our IP to life through high quality products and services.
          </p>
        </div>
      </motion.section>

      {/* Team */}
<motion.section
  id="team"
  className="scroll-mt-28 p-10 m-4 max-w-6xl mx-auto rounded-xl shadow bg-black/50 text-white"
  variants={fadeUp}
  initial="hidden"
  whileInView="show"
  viewport={{ once: true }}
>
  <h2 className="text-3xl font-sourgummy mb-4 text-center">Core Team</h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
    {[
      { name: 'Yummy', role: 'Artist', src: '/yummy.jpg' },
      { name: 'Mango', role: 'Business Developer', src: '/mango.png' },
      { name: 'Red', role: 'Brand Ambassador', src: '/red.jpg' },
      { name: 'MuffinMan', role: 'Developer', src: '/muffin.png' }, // New Member
    ].map((member, i) => (
      <motion.div
        key={i}
        whileHover={{ scale: 1.05 }}
        className="p-4 bg-black/60 text-white text-center rounded-lg shadow-lg"
      >
        <ImageFiller
          src={member.src}
          alt={member.name}
          width={160}
          height={160}
          className="rounded-lg mx-auto w-40 h-40 object-cover shadow-md float"
        />
        <h3 className="mt-4 text-xl font-bold">{member.name}</h3>
        <p className="text-sm">{member.role}</p>
      </motion.div>
    ))}
  </div>
</motion.section>


      {/* Merch */}
      <motion.section
        id="merch"
        className="scroll-mt-28 p-10 m-4 my-20 max-w-4xl mx-auto rounded-xl shadow bg-black/50 text-white"
        variants={fadeUp}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-sourgummy mb-4 flex items-center space-x-4">
          <img src="/nubox-logo.png" alt="NüBox logo" className="w-10 h-10 sm:w-12 sm:h-12 object-contain" />
          <span className="leading-tight">NüBox (Coming Soon)</span>
        </h2>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block px-6 py-3 mt-2 bg-blue-600 text-white rounded-full font-sourgummy hover:bg-blue-700 hover:scale-105 hover:shadow-xl transition-transform"
        >
          ❄️ Coming Soon
        </a>
      </motion.section>

      {/* Frostys Studio Section - Left Text, Bottom-Centered GIF */}
<motion.section
  id="mint"
  className="scroll-mt-28 p-10 m-4 my-20 max-w-4xl mx-auto rounded-xl shadow text-white relative overflow-hidden"
  style={{
    backgroundImage: "url('/football-field-bg.png')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
  variants={fadeUp}
  initial="hidden"
  whileInView="show"
  viewport={{ once: true }}
>
  {/* Background overlay for contrast */}
  <div className="absolute inset-0 bg-black/50 rounded-xl z-0"></div>

  {/* Content aligned left */}
  <div className="relative z-10 text-left">
    <h2 className="text-3xl font-sourgummy mb-4 flex items-center space-x-4">
      <img
        src="/giphy.png"
        alt="Studio Logo"
        className="w-10 h-10 sm:w-12 sm:h-12 object-contain"
      />
      <span className="leading-tight">Frostys Studio</span>
    </h2>

    <a
      href="https://giphy.com/channel/FrostysCo"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-block px-6 py-3 mt-2 bg-blue-600 text-white rounded-full font-sourgummy hover:bg-blue-700 hover:scale-105 hover:shadow-xl transition-transform"
    >
      GIPHY
    </a>
  </div>

  {/* Bottom-Centered Walrus GIF - perfectly touching bottom */}
  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10">
    <img
      src="/walrus-football.gif"
      alt="Walrus Playing Football"
      className="w-24 sm:w-28 md:w-32 object-contain"
    />
  </div>
</motion.section>




      {/* Gallery */}
      <motion.section
        id="gallery"
        className="scroll-mt-28 p-10 m-4 my-20 max-w-4xl mx-auto rounded-xl shadow bg-black/50 text-white"
        variants={fadeUp}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-sourgummy mb-4">Gallery</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {[
            { src: '/team1.jpg', alt: 'Frosty 1' },
            { src: '/team2.jpg', alt: 'Frosty 2' },
            { src: '/team3.png', alt: 'Frosty 3' },
          ].map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={img.alt}
              className="rounded-xl shadow-lg hover:scale-105 transition-transform object-contain cursor-pointer float"
              onClick={() => {
                setCurrentImage(img.src);
                setCurrentAlt(img.alt);
                setLightboxOpen(true);
              }}
            />
          ))}
        </div>
      </motion.section>

      {/* Lightbox */}
      <GalleryLightbox
        isOpen={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        imageSrc={currentImage}
        alt={currentAlt}
      />

      {/* Footer with Smaller Join Section and Black Text */}
<footer
  className="mt-16 mb-0 text-center text-black py-6 bg-cover bg-center relative z-0"
  style={{ backgroundImage: "url('/ice-floor-bg.png')" }}
>
  <p className="text-lg font-semibold mb-2">Join the Community</p>

  <div className="relative flex justify-center items-center mb-4 h-36 z-10">
    {/* Ice Hole */}
    <img
      src="/ice-hole.png"
      alt="Ice hole"
      className="absolute w-40 sm:w-48 md:w-52 lg:w-56 object-contain z-0"
      style={{ transform: "translate(0px, 40px)" }}
    />

    {/* Cloud Puff */}
    <div className="absolute bottom-[-10px] z-10 w-full flex justify-center animate-jiggle pointer-events-none">
      <div className="relative flex items-end -space-x-2 scale-90">
        <div className="w-9 h-9 bg-white rounded-full"></div>
        <div className="w-11 h-11 bg-white rounded-full"></div>
        <div className="w-13 h-13 bg-white rounded-full"></div>
        <div className="w-11 h-11 bg-white rounded-full"></div>
        <div className="w-9 h-9 bg-white rounded-full"></div>
      </div>
    </div>

    {/* Frosty GIF */}
    <img
      src="/frosty-phone.gif"
      alt="Frosty using phone"
      className="relative z-20 w-24 sm:w-28 md:w-32 object-contain translate-x-[14px] translate-y-[4px] sm:translate-x-[25px] sm:translate-y-[-10px] pointer-events-none"
    />
  </div>

  {/* Social Icons */}
  <div className="relative z-30 flex justify-center space-x-6">
    <img
      src="/discord-logo.png"
      alt="Discord"
      className="w-14 h-14 object-contain hover:scale-110 transition-transform cursor-pointer"
    />
    <a href="https://x.com/frostysco" target="_blank" rel="noopener noreferrer">
      <img
        src="/x-logo.png"
        alt="X / Twitter"
        className="w-14 h-14 object-contain hover:scale-110 transition-transform"
      />
    </a>
  </div>
</footer>


    </div>
  );
}

export default App;
